<template>
  <!-- 借岗管理页面 -->
  <div class="page">
    <div class="title">员工借岗信息</div>
    <input
      ref="fileInput"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      @change="importBorrow"
      @click="
        (event) => {
          event.target.value = null;
        }
      "
      style="width: 0; height: 0; position: absolute"
    />
    <div class="filter_box">
      <div class="filter_search">
        <el-select
          v-model="selectValue"
          placeholder="请选择"
          class="filter_select"
          @change="getSelValue"
        >
          <el-option
            v-for="item in selectArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          class="filter_search_input"
          placeholder="请输入姓名关键词"
          v-model="keyword"
          clearable
        >
        </el-input>
        <el-select
          style="margin-left: 16px"
          v-model="status"
          placeholder="请选择"
          filterable
          clearable
          class="filter_select"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="custom_button" @click="getTableList(1)">搜索</div>

        <div class="custom_button success" @click="chooseExcel">
          导入借岗考勤
        </div>

        <div class="custom_button warning" @click="exportRoster">
          导出借岗人员
        </div>
      </div>
    </div>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 15px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="staff_id" align="center" label="ID" width="120">
      </el-table-column>
      <el-table-column
        prop="real_name"
        align="center"
        label="员工姓名"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="borrow_duty_name"
        align="center"
        label="临时借岗部门"
      >
      </el-table-column>
      <el-table-column
        prop="borrow_station_name"
        align="center"
        label="临时借岗岗位"
      >
      </el-table-column>
      <el-table-column
        prop="borrow_salary_base"
        align="center"
        label="临时借岗工资"
      >
      </el-table-column>
      <el-table-column prop="status_value" align="center" label="借岗状态">
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        min-width="120"
      >
        <template v-slot:default="scope">
          <div class="operation">
            <!-- <div @click="showMsg">月度明细</div>
          | -->
            <div @click="revokeBtn(scope.row.id)">中止借岗</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 明细信息 -->
    <!-- <el-dialog title="借岗明细" :visible.sync="dialogVisible1" width="80%">
      <el-table
        :data="attendancedetail"
        :header-cell-style="tableHeaderStyle"
        :row-class-name="tableRowClassName"
        :cell-style="tableCellStyle"
        style="width: 100%"
      >
        <el-table-column prop="real_name" label="姓名"></el-table-column>
        <el-table-column prop="station_name" label="身份证号"></el-table-column>
        <el-table-column
          prop="should_attendance"
          label="借岗岗位"
        ></el-table-column>
        <el-table-column
          prop="actual_attendance"
          label="实际借岗出勤天数"
        ></el-table-column>
        <el-table-column
          prop="legal_overtime"
          label="法定加班"
        ></el-table-column>
        <el-table-column
          prop="week_overtime"
          label="周末加班"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_first"
          label="加点替岗1"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_second"
          label="加点替岗2"
        ></el-table-column>
        <el-table-column prop="change_off" label="换休"></el-table-column>
        <el-table-column prop="paid_vacation" label="有薪假	"></el-table-column>
        <el-table-column prop="stay_away" label="事假"></el-table-column>
        <el-table-column prop="without" label="旷工"></el-table-column>
        <el-table-column prop="injury_leave" label="工伤假"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-dialog> -->

    <!-- 分页 -->
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="getTableList($event)"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      keyword: "", // 搜索的值
      dialogVisible: false, // 控制弹框
      tableData: [],
      // dialogVisible1: true,
      // 分页参数
      page: {
        size: 10,
        page: 1,
        total: 0,
      },
      // 选择状态
      status: "",
      // 选择搜索选项
      selectValue: 1,
      //下拉框
      selectArr: [
        {
          label: "员工ID",
          value: 1,
        },
        {
          label: "员工姓名",
          value: 2,
        },
        {
          label: "员工手机号码",
          value: 3,
        },
      ],
      statusList: [
        {
          label: "中止",
          value: 0,
        },
        {
          label: "生效中",
          value: 1,
        },
      ],
      multipleSelection: [],
    };
  },
  created() {
    // this.loadData();
    this.getTableList();
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("employee", [
      "getBorrowTable",
      "stopBorrow",
      "delBorrow",
      "borrowImport",
    ]),
    ...mapActions("postTransfer", ["exportTransfer"]),
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 下来选择搜索关键词
    getSelValue(e) {
      console.log(e);
      this.placeholderWord = `请输入${this.selectArr[e - 1].label}`;
      this.keyword = "";
    },
    //选择excel
    chooseExcel() {
      this.$refs.fileInput.click();
    },
    // 导入excel
    importBorrow(file) {
      console.log(file, "file");

      let loading = this.$loading();
      try {
        this.uploadFile({
          file: file.target.files[0],
          type: "staff_xls",
        }).then((res) => {
          let link = {
            fileUrl: res.data.data.link,
            local_name: file.target.files[0].name,
          };
          console.log(res, link, "link");
          this.borrowImport(link)
            .then((res) => {
              this.getTableList();
              let error_remark = res.data.data.error_remark;
              if (error_remark.length > 0) {
                this.$confirm(error_remark.join(), "提示", {
                  confirmButtonText: "确定",
                  type: "error",
                });
              } else {
                this.$alert(res.data.msg, "成功", {
                  confirmButtonText: "确定",
                  type: "success",
                });
              }
            })
            .catch((err) => {
              console.log("上传失败", err);
              this.$confirm(err.message, "提示", {
                confirmButtonText: "确定",
                type: "error",
              });
            });
        });
        loading.close();

        this.loadData();
      } catch (e) {
        loading.close();
        console.error(e);
        this.$message.error(e.msg);
        this.$alert(e.msg, "成功", {
          confirmButtonText: "确定",
          type: "success",
        });
      }
    },
    // 列表  搜索
    getTableList(page = 1) {
      let params = {
        size: this.page.size,
        page,
      };

      if (this.keyword.trim().length > 0) {
        switch (this.selectValue) {
          case 1:
            params.staff_id = this.keyword;
            break;
          case 2:
            params.real_name = this.keyword;
            break;
          case 3:
            params.mobile = this.keyword;
            break;
          default:
            break;
        }
      }
      if (this.status !== "") {
        params.status = this.status;
      }
      console.log(params);
      this.getBorrowTable(params).then((res) => {
        console.log("借岗列表", res);
        if (res.data.code == 200) {
          let { list, total } = res.data.data;
          this.tableData = list;
          this.page.total = total;
        }
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查看信息
    showMsg() {
      this.dialogVisible = true;
    },
    // 撤销申请
    revokeBtn(id) {
      console.log("ids", id);
      this.$confirm("是否中止借岗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.removeTableList(id);
        })
        .catch(() => {});
    },
    // 撤销申请  删除记录   刷新列表
    async removeTableList(id) {
      await this.stopBorrow({ id })
        .then((res) => {
          console.log("撤销申请", res);
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err, "error");
          this.$message({
            message: err.message,
            type: "error",
          });
        });
      await this.delBorrow({ id }).then((res) => {
        console.log("删除记录", res);
      });
      await this.getTableList();
    },

    // 导出调岗信息
    exportRoster() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择员工",
          type: "warning",
        });
        return;
      }
      let idList = this.multipleSelection.reduce((prev, next) => {
        console.log(prev, next.id);
        next.id && prev.push(next.id);
        return prev;
      }, []);
      console.log("导出花名册", idList);

      let loading = this.$loading();
      this.exportTransfer({
        url: `web/staff/exportBorrow?id=${idList.join(",")}`,
        name: "借岗人员表",
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.data.message, "导出借岗人员表", {
            confirmButtonText: "确定",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },

    // 分页数据
    loadData(func) {
      // 单例初始化
      if (!this.loadDataObj)
        this.loadDataObj = new LoadDataClass(this.getBorrowTable);

      // 通知，用于修改对象属性
      if (func) func(this.loadDataObj);

      let loading = this.$loading();
      this.loadDataObj
        .load(this.page.page)
        .then((res) => {
          console.log("staff", res);
          res.data.data.list.map((item, index) => {
            item.index = index;
          });
          this.tableData = res.data.data.list;

          this.page.total = res.data.data.total;
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },
  },
};

class LoadDataClass {
  constructor(request, nav_type = null, keyword = null) {
    this.nav_type = nav_type;
    this.keyword = keyword;
    this.func = request;
  }

  load(page) {
    let params = {
      page,
    };
    if (this.keyword) params.keywords = this.keyword;
    if (this.nav_type) params.nav_type = this.nav_type;
    return this.func(params);
  }
}
</script>

<style scoped>
.title {
  width: 100%;
  background: #ffff;
  padding: 20px 12px;
}
.filter_search_input {
  width: 20%;
}
.custom_button {
  display: inline-block;
  margin-left: 20px;
}
.warning {
  background-color: #f39c12;
}
.operation > div {
  display: inline-block;
  color: rgb(24, 144, 255);
}
.operation > div:hover {
  cursor: pointer;
}

.table_center ::v-deep .el-table_2_column_14,
.table_center ::v-deep .el-table_2_column_15 {
  background-color: rgb(242, 242, 242);
}
.table_center ::v-deep .is-leaf {
  background-color: #efb252;
  text-align: center;
  color: #333;
}
.table_center ::v-deep .cell {
  text-align: center;
}
.filter_select {
  width: 145px;
}
</style>
